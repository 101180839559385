@mixin margin($val: 1, $pos: all) {
  @if $pos == all {
    margin: #{$val}px !important;
  } @else if $pos == x {
    margin-left: #{$val}px !important;
    margin-right: #{$val}px !important;
  } @else if $pos == y {
    margin-top: #{$val}px !important;
    margin-bottom: #{$val}px !important;
  } @else {
    margin-#{$pos}: #{$val}px !important;
  }
}

@mixin padding($val: 1, $pos: all) {
  @if $pos == all {
    padding: #{$val}px !important;
  } @else if $pos == x {
    padding-left: #{$val}px !important;
    padding-right: #{$val}px !important;
  } @else if $pos == y {
    padding-top: #{$val}px !important;
    padding-bottom: #{$val}px !important;
  } @else {
    padding-#{$pos}: #{$val}px !important;
  }
}

@mixin border($pos: all, $c: $c-d8, $val: 1px) {
  @if $pos == all {
    border: #{$val} solid #{$c};
  } @else if $pos == x {
    border-left: #{$val} solid #{$c};
    border-right: #{$val} solid #{$c};
  } @else if $pos == y {
    border-top: #{$val} solid #{$c};
    border-left: #{$val} solid #{$c};
  } @else {
    border-#{$pos}: #{$val} solid #{$c};
  }
}

@mixin bg-img-zhong-zhi($url: '') {
  background-image: url('/resources/images/#{$url}');
}

@mixin border($w, $c, $pos: all) {
  @if $pos == all {
    border: #{$w}px solid $c;
  } @else if $pos == x {
    border-left: #{$w}px solid $c;
    border-right: #{$w}px solid $c;
  } @else if $pos == y {
    border-top: #{$w}px solid $c;
    border-bottom: #{$w}px solid $c;
  } @else {
    border-#{$pos}: #{$w}px solid $c;
  }
}

@mixin triangle($direction: bottom, $w: 30, $h: 20, $c: $c-main) {
  @if $direction == bottom {
    @include border($w / 2, transparent, x);
    @include border($h, $c, top);
  }
  @if $direction == top {
    @include border($w / 2, transparent, x);
    @include border($h, $c, bottom);
  }
  @if $direction == left {
    @include border($h / 2, transparent, y);
    @include border($w, $c, right);
  }
  @if $direction == right {
    @include border($h / 2, transparent, y);
    @include border($w, $c, left);
  }
}

$white: #fff;
$c-main: #13BB74;
$black: #000;
$c333: #333;
$c666: #666;
$c999: #999;
$c-d8: #D8D8D8;
$c-eb: #EBEBEB;
$c-text: #505253;
$m-hook: #3473f0;
// $wrap: 180px;
$wrap: 360px;
$header-height: 80px;

.teach_plan_page {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  padding: 30px 0;

  .teach_plan_page_con {
    margin: 0 306px;
    width: 940px;
    height: 855px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #D6D8DC;

    .teach_plan_page_conno {
      // margin-top: 280px;
      text-align: center;
      .ant-upload-list{
        display: none;
      }
      img {
        width: 94px;
        height: 73px;
        margin-bottom: 26px;
      }

      .button {
        width: 78px;
        height: 32px;
        line-height: 32px;
        background: #2252F2;
        border: none;
        border-radius: 4px;
        font-size: 14px;
        color: #FFFFFF;
      }
      p{
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8E9AB3;
        line-height: 20px;
        margin-top: 15px;
      }
    }
  }
}
