.component-paginations {
  .ant-pagination-item {
    border: none;
  }

  .ant-pagination-item-link {
    border: none;
  }

  .ant-pagination {
    display: flex;
    justify-content: center;
  }

  .anticon {
    display: flex;
    align-items: center;
    justify-items: center;
    position: relative;
    top: -0.015rem;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    background: #F5F6F8;
    border-radius: 6px;
    padding: 0 .1rem;
    color: #2252F2;
    font-size: 13px;
    min-width: 26px;
    height: 26px;
    line-height: 26px;
    border-radius: 6px;
    a{
      color: #2252F2;
    }
  }

  .ant-pagination-item {
    background: #F5F6F8;
    mix-blend-mode: normal;
    border: 1px solid #E7EBF2;
    box-sizing: border-box;
    border-radius: 6px;
    min-width: 26px;
    height: 26px;
    line-height: 24px;
    color: #2252F2;
    font-size: 13px;
    a{
      color: #2252F2;
    }
   
  }
  .ant-pagination-disabled{
     a {
      color: rgba(0, 0, 0, 0.25);
    }

  }

  .ant-pagination-item-active {
    // background: #FFFFFF;
    // border: 1px solid #EA4341;
    color: #FFFFFF;
    font-size: 13px;
    background: #2252F2;
    box-sizing: border-box;
    border-radius: 6px;
    a{
      color: #FFFFFF;
    }
  }

  .ant-pagination-item-active a {
    // color: #EA4341;
    // background: #2252F2;
  }

  .ant-pagination-total-text {
    // float: right;
  }

  // .ant-pagination-item-link {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // }
  .ant-pagination-item-container{
    height: 100%;
    .ant-pagination-item-link-icon{
      height: 100%;
      svg{
        margin: auto;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
}
@primary-color: #2252F2;