@mixin margin($val: 1, $pos: all) {
  @if $pos == all {
    margin: #{$val}px !important;
  } @else if $pos == x {
    margin-left: #{$val}px !important;
    margin-right: #{$val}px !important;
  } @else if $pos == y {
    margin-top: #{$val}px !important;
    margin-bottom: #{$val}px !important;
  } @else {
    margin-#{$pos}: #{$val}px !important;
  }
}

@mixin padding($val: 1, $pos: all) {
  @if $pos == all {
    padding: #{$val}px !important;
  } @else if $pos == x {
    padding-left: #{$val}px !important;
    padding-right: #{$val}px !important;
  } @else if $pos == y {
    padding-top: #{$val}px !important;
    padding-bottom: #{$val}px !important;
  } @else {
    padding-#{$pos}: #{$val}px !important;
  }
}

@mixin border($pos: all, $c: $c-d8, $val: 1px) {
  @if $pos == all {
    border: #{$val} solid #{$c};
  } @else if $pos == x {
    border-left: #{$val} solid #{$c};
    border-right: #{$val} solid #{$c};
  } @else if $pos == y {
    border-top: #{$val} solid #{$c};
    border-left: #{$val} solid #{$c};
  } @else {
    border-#{$pos}: #{$val} solid #{$c};
  }
}

@mixin bg-img-zhong-zhi($url: '') {
  background-image: url('/resources/images/#{$url}');
}

@mixin border($w, $c, $pos: all) {
  @if $pos == all {
    border: #{$w}px solid $c;
  } @else if $pos == x {
    border-left: #{$w}px solid $c;
    border-right: #{$w}px solid $c;
  } @else if $pos == y {
    border-top: #{$w}px solid $c;
    border-bottom: #{$w}px solid $c;
  } @else {
    border-#{$pos}: #{$w}px solid $c;
  }
}

@mixin triangle($direction: bottom, $w: 30, $h: 20, $c: $c-main) {
  @if $direction == bottom {
    @include border($w / 2, transparent, x);
    @include border($h, $c, top);
  }
  @if $direction == top {
    @include border($w / 2, transparent, x);
    @include border($h, $c, bottom);
  }
  @if $direction == left {
    @include border($h / 2, transparent, y);
    @include border($w, $c, right);
  }
  @if $direction == right {
    @include border($h / 2, transparent, y);
    @include border($w, $c, left);
  }
}

$white: #fff;
$c-main: #13BB74;
$black: #000;
$c333: #333;
$c666: #666;
$c999: #999;
$c-d8: #D8D8D8;
$c-eb: #EBEBEB;
$c-text: #505253;
$m-hook: #3473f0;
// $wrap: 180px;
$wrap: 360px;
$header-height: 80px;

.sheader-logo1{
  width: auto !important;
}
.sheader {
  &-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: $white;
    z-index: 99;
    padding: 0 $wrap;
    height: $header-height;
  }

  &-logo {
    width: 167px;
    margin-right: 84px;

    &.jiaoXueKongJian {
      width: 85px;
      height: 54px;
      margin-right: 31px;
    }

    // img {
    //   max-width: 100%;
    // }

    .homeLogoContainer {
      display: inline-block;
      height: 64px;
      line-height: 64px;
      // margin-right: 103px;
      vertical-align: middle;
      cursor: pointer;
      img {
        height: 64px;
        vertical-align: middle;
      }

      .homeLogoRight {
        display: inline-block;
        vertical-align: middle;
        text-align: left;
        color: #17181A;
        margin-left: 5px;
        // margin-right: 138px;
        .homeLogoRightT {
          height: 32px;
          font-size: 32px;
          line-height: 32px;
          margin-bottom: 10px;
          white-space: nowrap;
        }
      
        .homeLogoRightB {
          height: 14px;
          font-size: 14px;
          line-height: 0;
          white-space: nowrap;
          margin-top: 15px;
        }
      }
    }

  }
  
  &-jiaoXueKongJianLine {
    display: inline-block;
    width: 1px;
    height: 30px;
    background: #D6D8DC;
    margin-right: 30px;
  }

  &-jiaoXueKongJianText {
    font-size: 22px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #17181A;
    line-height: 30px;
    margin-right: 138px;
  }

  &-user {

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 366px;
    line-height: 12px;
    padding-left: 38px;

    .sheader-user-img-container {
      position: absolute;
      left: 0px;
      top: -5px;
      width: 27px;
      height: 33px;
      cursor: pointer;

      &:hover {
        .sheaderUserexit {
          display: block;
        }
      }

      .sheader-user-img {
        width: 30px;
          height: 30px;
        border-radius: 50%;
        // vertical-align: middle;
      }

      .sheaderUserexit {
        display: none;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 33px;
        width: 50px;
        height: 30px;
        line-height: 30px;
        background: #FFFFFF;
        box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
        border-radius: 16px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #17181A;
        text-align: center;
        cursor: pointer;
      }
    }

    // .sheader-user-img {
    //   width: 27px;
    //   height: 27px;
    //   border-radius: 50%;
    //   margin-right: 11px;
    //   vertical-align: middle;
    // }

    .sheader-user-name {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #686F7E;
      line-height: 12px;
      margin-right: 36px;
      vertical-align: middle;
    }

    .sheader-user-message {
      display: inline-block;
      position: relative;
      width: 20px;
      // width: 16px;
      // height: 15px;
      vertical-align: middle;
      cursor: pointer;

      .sheader-user-message {
        width: 100%;
        height: 100%;
      }

      .sheader-user-message-tip {
        position: absolute;
        width: 6px;
        height: 6px;
        top: 0px;
        right: -2px;
        // width: 8px;
        // height: 8px;
        // top: -4px;
        // right: -4px;
        background: #FB0733;
        border-radius: 50%;
      }
    }

    // &__img {
    //   width: 50px;
    //   height: 50px;
    //   background-color: #cfd1d6 !important;
    //   border-radius: 50%;
    // }
  }


}

.downApp {
  margin-right: 30px;
  color: black;
}

.navWrap {
  position: relative;
}
