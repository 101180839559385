.preview-page {

  //width: 100vw;
  height: 100%;
  margin: 1rem auto 0;
  display: flex;
  justify-content:center;


  .preview-page-content {

    // overflow-y: hidden;
    // height: 850px;

    height: 100%;
    width: 70%;
    max-width:1000px;
    display: flex;
    flex-flow: column;

    padding:1rem 1rem 2rem;

    background: white;
  }
}

@primary-color: #2252F2;