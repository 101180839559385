@mixin margin($val: 1, $pos: all) {
  @if $pos == all {
    margin: #{$val}px !important;
  } @else if $pos == x {
    margin-left: #{$val}px !important;
    margin-right: #{$val}px !important;
  } @else if $pos == y {
    margin-top: #{$val}px !important;
    margin-bottom: #{$val}px !important;
  } @else {
    margin-#{$pos}: #{$val}px !important;
  }
}

@mixin padding($val: 1, $pos: all) {
  @if $pos == all {
    padding: #{$val}px !important;
  } @else if $pos == x {
    padding-left: #{$val}px !important;
    padding-right: #{$val}px !important;
  } @else if $pos == y {
    padding-top: #{$val}px !important;
    padding-bottom: #{$val}px !important;
  } @else {
    padding-#{$pos}: #{$val}px !important;
  }
}

@mixin border($pos: all, $c: $c-d8, $val: 1px) {
  @if $pos == all {
    border: #{$val} solid #{$c};
  } @else if $pos == x {
    border-left: #{$val} solid #{$c};
    border-right: #{$val} solid #{$c};
  } @else if $pos == y {
    border-top: #{$val} solid #{$c};
    border-left: #{$val} solid #{$c};
  } @else {
    border-#{$pos}: #{$val} solid #{$c};
  }
}

@mixin bg-img-zhong-zhi($url: '') {
  background-image: url('/resources/images/#{$url}');
}

@mixin border($w, $c, $pos: all) {
  @if $pos == all {
    border: #{$w}px solid $c;
  } @else if $pos == x {
    border-left: #{$w}px solid $c;
    border-right: #{$w}px solid $c;
  } @else if $pos == y {
    border-top: #{$w}px solid $c;
    border-bottom: #{$w}px solid $c;
  } @else {
    border-#{$pos}: #{$w}px solid $c;
  }
}

@mixin triangle($direction: bottom, $w: 30, $h: 20, $c: $c-main) {
  @if $direction == bottom {
    @include border($w / 2, transparent, x);
    @include border($h, $c, top);
  }
  @if $direction == top {
    @include border($w / 2, transparent, x);
    @include border($h, $c, bottom);
  }
  @if $direction == left {
    @include border($h / 2, transparent, y);
    @include border($w, $c, right);
  }
  @if $direction == right {
    @include border($h / 2, transparent, y);
    @include border($w, $c, left);
  }
}

$white: #fff;
$c-main: #13BB74;
$black: #000;
$c333: #333;
$c666: #666;
$c999: #999;
$c-d8: #D8D8D8;
$c-eb: #EBEBEB;
$c-text: #505253;
$m-hook: #3473f0;
// $wrap: 180px;
$wrap: 360px;
$header-height: 80px;

.slayout {
  &-main {
    padding-top: $header-height;
  }

  &-footer {
    // padding: 18px 0;
    position: relative;
    height: 218px;
    color: rgba($color: #000000, $alpha: .75);
    background: #212738;
    font-size: 12px;
    font-family: 'PingFangSC-Regular', 'PingFang SC';
    font-weight: 400;
    color: #FFFFFF;
    line-height: 12px;

    .bottomContainerLayout {
      position: absolute;
      bottom: 70px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;

      font-size: 12px;
      font-family: 'PingFangSC-Regular', 'PingFang SC';
      font-weight: 400;
      color: #FFFFFF;

      .bottomlineLayout {
        margin: 0 33px;
      }

      p {
        margin-top: 30px;
      }

      a {
        text-decoration: none;
      }

      span,
      p,
      a {
        line-height: 12px;
      }
    }

    .bottomContainerTwo {
      padding-top: 43px;
      text-align: center;

      .schoolName {
        font-size: 22px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 32px;
        margin-bottom: 24px;
      }

      .addreddPhoneEmail,
      .agreement {
        margin-bottom: 40px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 18px;

        span {
          margin: 0 20px;
        }
      }
    }
  }
}

.slayout-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // overflow: hidden;

  header {
    // height: 80px;
  }
  // position: fixed;
  .slayout-main {
    flex: 1;
    // overflow: auto;
    // height: 100%;
    background: #F5F6F8;
    // overflow: hidden;
// padding-top:0;
  }
}
