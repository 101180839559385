@mixin margin($val: 1, $pos: all) {
  @if $pos == all {
    margin: #{$val}px !important;
  } @else if $pos == x {
    margin-left: #{$val}px !important;
    margin-right: #{$val}px !important;
  } @else if $pos == y {
    margin-top: #{$val}px !important;
    margin-bottom: #{$val}px !important;
  } @else {
    margin-#{$pos}: #{$val}px !important;
  }
}

@mixin padding($val: 1, $pos: all) {
  @if $pos == all {
    padding: #{$val}px !important;
  } @else if $pos == x {
    padding-left: #{$val}px !important;
    padding-right: #{$val}px !important;
  } @else if $pos == y {
    padding-top: #{$val}px !important;
    padding-bottom: #{$val}px !important;
  } @else {
    padding-#{$pos}: #{$val}px !important;
  }
}

@mixin border($pos: all, $c: $c-d8, $val: 1px) {
  @if $pos == all {
    border: #{$val} solid #{$c};
  } @else if $pos == x {
    border-left: #{$val} solid #{$c};
    border-right: #{$val} solid #{$c};
  } @else if $pos == y {
    border-top: #{$val} solid #{$c};
    border-left: #{$val} solid #{$c};
  } @else {
    border-#{$pos}: #{$val} solid #{$c};
  }
}

@mixin bg-img-zhong-zhi($url: '') {
  background-image: url('/resources/images/#{$url}');
}

@mixin border($w, $c, $pos: all) {
  @if $pos == all {
    border: #{$w}px solid $c;
  } @else if $pos == x {
    border-left: #{$w}px solid $c;
    border-right: #{$w}px solid $c;
  } @else if $pos == y {
    border-top: #{$w}px solid $c;
    border-bottom: #{$w}px solid $c;
  } @else {
    border-#{$pos}: #{$w}px solid $c;
  }
}

@mixin triangle($direction: bottom, $w: 30, $h: 20, $c: $c-main) {
  @if $direction == bottom {
    @include border($w / 2, transparent, x);
    @include border($h, $c, top);
  }
  @if $direction == top {
    @include border($w / 2, transparent, x);
    @include border($h, $c, bottom);
  }
  @if $direction == left {
    @include border($h / 2, transparent, y);
    @include border($w, $c, right);
  }
  @if $direction == right {
    @include border($h / 2, transparent, y);
    @include border($w, $c, left);
  }
}

$white: #fff;
$c-main: #13BB74;
$black: #000;
$c333: #333;
$c666: #666;
$c999: #999;
$c-d8: #D8D8D8;
$c-eb: #EBEBEB;
$c-text: #505253;
$m-hook: #3473f0;
// $wrap: 180px;
$wrap: 360px;
$header-height: 80px;

.topicSelectionOfQuestionBankWraper {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  background-color: #A8A9AB;

  .topicSelectionOfQuestionBankContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1140px;
    height: 90%;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px 0px rgba(12, 12, 13, 0.1);
    border-radius: 2px;
    border: 1px solid rgba(214, 216, 220, 0.6);
    padding: 20px;
    padding-top: 0;
    overflow-y: auto;
    overflow-x: hidden;

    .title {
      height: 54px;
      line-height: 54px;
      border-bottom: 1px solid #E6E8EA;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #17181A;
      margin-bottom: 25px;
    }

    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }



    .header {
      border-bottom: 1px solid rgba(214, 216, 220, 0.6);
      margin-bottom: 35px;

      .nav {
        margin-bottom: 25px;
        font-size: 0;

        .navName {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #8E9AB3;
          margin-right: 10px;
          vertical-align: top;
          line-height: 32px;
        }

        .navItem {
          display: inline-block;
          line-height: 32px;
          max-width: 92%;
          vertical-align: top;

          span {
            display: inline-block;
            height: 32px;
            line-height: 32px;
            padding: 0 16px;
            margin-right: 10px;
            border-radius: 4px;
            background: #FFFFFF;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #17181A;
            cursor: pointer;
            &.active {
              font-weight: 500;
              color: #2252F2;
            }

            &:hover {
              background: #E9EFFE;
              font-weight: 500;
              color: #2252F2;
            }
          }
        }
      }
    }

    .testItem {
      padding: 20px;
      padding-bottom: 0;
      border-radius: 16px;
      border: 1px solid rgba(214, 216, 220, 0.6);
      text-align: left;
      margin-bottom: 20px;

      &.active {
        border: 1px solid #6DE18D;
      }

      .stem {
        font-size: 16px;
        font-family: TimesNewRomanPSMT;
        color: #17181A;
        margin-bottom: 40px;

        .stemL {
          display: inline-block;
          vertical-align: top;
          width: 3%;
        }

        .stemR {
          display: inline-block;
          vertical-align: top;
          width: 96%;

          // * {
          //   display: inline-block;
          // }
        }

        // * {
        //   display: inline-block;
        // }

        img {
          max-width: 100%;
        }
      }

      .answer_vo {
        padding-left: 20px;
        margin-bottom: 40px;

        .answer_content {
          display: inline-block;
          vertical-align: top;
        }
      }

      .showAnalysisBtnContainer {
        text-align: right;
        margin-top: 11px;
        margin-bottom: 9px;

        .showAnalysisBtn {
          display: inline-block;
          width: 70px;
          height: 24px;
          line-height: 24px;
          cursor: pointer;
          background: #E8FBEA;
          margin-right: 10px;
          text-align: center;
          // position: relative;

          // .showAnalysisBtnImg {
          //   width: 100%;
          //   height: 100%;
          // }

          &.showAnalysisBtn1 {
            background: #FFF1EC;

            .showAnalysisBtnContent1 {
              span {
                color: #FA541C;
              }
            }

            .showAnalysisBtnContent2 {
              background: #F5F6F8;

              span {
                color: #8E9AB3;
              }
            }

            .showAnalysisBtnContent3 {
              background: #E8FBEA;

              span {
                color: #0FBD4E;
              }
            }
          }

          .showAnalysisBtnContent {
            // position: absolute;
            // left: 63%;
            // top: 50%;
            // transform: translate(-50%, -50%);
            white-space: nowrap;

            span {
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #0FBD4E;
              // line-height: 16px;
              vertical-align: middle;
            }

            img {
              width: 18px;
              height: 18px;
              vertical-align: middle;
            }
          }
        }
      }

      .analysisContainer {
        background: #E8FBEA;
        border-radius: 10px 0px 10px 10px;
        padding: 10px;
        margin-bottom: 16px;
        font-size: 16px;
        font-family: STKaitiTC-Black, STKaitiTC;
        font-weight: 900;
        color: #17181A;
        line-height: 22px;

        .analysisContainerT {
          margin-bottom: 20px;
          * {
            display: inline-block;
          }

          img {
            max-width: 100%;
          }
        }

        .analysisContainerB {
          white-space: nowrap;

          .analysisLeft {
            display: inline-block;
            vertical-align: top;
          }

          .analysisRight {
            white-space: normal;
            display: inline-block;
            vertical-align: top;
            width: 90%;
          }
        }
      }
    }

    .empty {
      position: relative;
      height: 63%;
    }

    .footer {
      .btnContainer {
        margin-top: 35px;
        text-align: center;

        .btnL,
        .btnR {
          display: inline-block;
          vertical-align: middle;
          width: 68px;
          height: 32px;
          line-height: 32px;
          background: #FFFFFF;
          border-radius: 4px;
          border: 1px solid #8E9AB3;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #17181A;
          margin-right: 10px;
          cursor: pointer;
        }

        .btnR {
          margin-right: 0;
          background: #2252F2;
          border: 1px solid #2252F2;
          color: #FFFFFF;
        }
      }
    }
  }

  .numberWraper {
    position: fixed;
    top: 5%;
    right: calc((100% - 1140px)/2 - 48px);
    width: 48px;
    height: 140px;
    background: linear-gradient(262deg, #478AFA 0%, #2252F2 100%);
    box-shadow: 0px 4px 16px 0px rgba(12, 12, 13, 0.1);
    border-radius: 2px 20px 20px 2px;
    text-align: center;

    img {
      width: 18px;
      height: 18px;
      margin-top: 11px;
      margin-bottom: 5px;
    }

    .number {
      display: inline-block;
      width: 34px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      // background: #333;
      background: #666;
      border-radius: 4px;
      // opacity: 0.2;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
    }

    .text {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 25px;
    }
  }
}
