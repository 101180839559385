@mixin margin($val: 1, $pos: all) {
  @if $pos == all {
    margin: #{$val}px !important;
  } @else if $pos == x {
    margin-left: #{$val}px !important;
    margin-right: #{$val}px !important;
  } @else if $pos == y {
    margin-top: #{$val}px !important;
    margin-bottom: #{$val}px !important;
  } @else {
    margin-#{$pos}: #{$val}px !important;
  }
}

@mixin padding($val: 1, $pos: all) {
  @if $pos == all {
    padding: #{$val}px !important;
  } @else if $pos == x {
    padding-left: #{$val}px !important;
    padding-right: #{$val}px !important;
  } @else if $pos == y {
    padding-top: #{$val}px !important;
    padding-bottom: #{$val}px !important;
  } @else {
    padding-#{$pos}: #{$val}px !important;
  }
}

@mixin border($pos: all, $c: $c-d8, $val: 1px) {
  @if $pos == all {
    border: #{$val} solid #{$c};
  } @else if $pos == x {
    border-left: #{$val} solid #{$c};
    border-right: #{$val} solid #{$c};
  } @else if $pos == y {
    border-top: #{$val} solid #{$c};
    border-left: #{$val} solid #{$c};
  } @else {
    border-#{$pos}: #{$val} solid #{$c};
  }
}

@mixin bg-img-zhong-zhi($url: '') {
  background-image: url('/resources/images/#{$url}');
}

@mixin border($w, $c, $pos: all) {
  @if $pos == all {
    border: #{$w}px solid $c;
  } @else if $pos == x {
    border-left: #{$w}px solid $c;
    border-right: #{$w}px solid $c;
  } @else if $pos == y {
    border-top: #{$w}px solid $c;
    border-bottom: #{$w}px solid $c;
  } @else {
    border-#{$pos}: #{$w}px solid $c;
  }
}

@mixin triangle($direction: bottom, $w: 30, $h: 20, $c: $c-main) {
  @if $direction == bottom {
    @include border($w / 2, transparent, x);
    @include border($h, $c, top);
  }
  @if $direction == top {
    @include border($w / 2, transparent, x);
    @include border($h, $c, bottom);
  }
  @if $direction == left {
    @include border($h / 2, transparent, y);
    @include border($w, $c, right);
  }
  @if $direction == right {
    @include border($h / 2, transparent, y);
    @include border($w, $c, left);
  }
}

$white: #fff;
$c-main: #13BB74;
$black: #000;
$c333: #333;
$c666: #666;
$c999: #999;
$c-d8: #D8D8D8;
$c-eb: #EBEBEB;
$c-text: #505253;
$m-hook: #3473f0;
// $wrap: 180px;
$wrap: 360px;
$header-height: 80px;

.audio-wrapper {
  display: flex;
  flex-direction: row;
  height: 80px;
  align-items: center;
  // justify-content: space-around;
  // margin: 10px auto;
  padding: 20px;
  background: #F5F6F8;
  border-radius: 4px;

  >div {
    &.playbtn {
      width: auto;
      padding-right: 15px;
      flex: none;
      img{
        width: 40px;
        height: 40px;
      }
    }

    &.playright {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      font-size: 16px;

      .playright_top {
        height: 25px;
        line-height: 25px;
        display: flex;
        justify-content: space-between;
        width: 100%;


        .playtimer {
          flex-basis: auto;
          padding-left: 24px;
        }
      }

      .playprocess {
        flex: 1;

        .ant-slider {
          width: 100%;
          height: 18px;
          margin: 1px;

          >.ant-slider-rail,
          >.ant-slider-track,
          >.ant-slider-step {
            height: 10px;
          }

          >.ant-slider-rail {
            background-color: #D6D8DC;
          }

          >.ant-slider-track {
            background-color:#2252F2
          }

          >.ant-slider-step {
          }

          >.ant-slider-handle {
            width: 20px;
            height: 20px;
            margin-top: -4px;
            border: 6px solid #FFFFFF;
            background-color: #2252F2;
          }
          >.ant-slider-handle:focus{
            box-shadow: none;
          }
        }
      }

    }




  }
}
