@mixin margin($val: 1, $pos: all) {
  @if $pos == all {
    margin: #{$val}px !important;
  } @else if $pos == x {
    margin-left: #{$val}px !important;
    margin-right: #{$val}px !important;
  } @else if $pos == y {
    margin-top: #{$val}px !important;
    margin-bottom: #{$val}px !important;
  } @else {
    margin-#{$pos}: #{$val}px !important;
  }
}

@mixin padding($val: 1, $pos: all) {
  @if $pos == all {
    padding: #{$val}px !important;
  } @else if $pos == x {
    padding-left: #{$val}px !important;
    padding-right: #{$val}px !important;
  } @else if $pos == y {
    padding-top: #{$val}px !important;
    padding-bottom: #{$val}px !important;
  } @else {
    padding-#{$pos}: #{$val}px !important;
  }
}

@mixin border($pos: all, $c: $c-d8, $val: 1px) {
  @if $pos == all {
    border: #{$val} solid #{$c};
  } @else if $pos == x {
    border-left: #{$val} solid #{$c};
    border-right: #{$val} solid #{$c};
  } @else if $pos == y {
    border-top: #{$val} solid #{$c};
    border-left: #{$val} solid #{$c};
  } @else {
    border-#{$pos}: #{$val} solid #{$c};
  }
}

@mixin bg-img-zhong-zhi($url: '') {
  background-image: url('/resources/images/#{$url}');
}

@mixin border($w, $c, $pos: all) {
  @if $pos == all {
    border: #{$w}px solid $c;
  } @else if $pos == x {
    border-left: #{$w}px solid $c;
    border-right: #{$w}px solid $c;
  } @else if $pos == y {
    border-top: #{$w}px solid $c;
    border-bottom: #{$w}px solid $c;
  } @else {
    border-#{$pos}: #{$w}px solid $c;
  }
}

@mixin triangle($direction: bottom, $w: 30, $h: 20, $c: $c-main) {
  @if $direction == bottom {
    @include border($w / 2, transparent, x);
    @include border($h, $c, top);
  }
  @if $direction == top {
    @include border($w / 2, transparent, x);
    @include border($h, $c, bottom);
  }
  @if $direction == left {
    @include border($h / 2, transparent, y);
    @include border($w, $c, right);
  }
  @if $direction == right {
    @include border($h / 2, transparent, y);
    @include border($w, $c, left);
  }
}

$white: #fff;
$c-main: #13BB74;
$black: #000;
$c333: #333;
$c666: #666;
$c999: #999;
$c-d8: #D8D8D8;
$c-eb: #EBEBEB;
$c-text: #505253;
$m-hook: #3473f0;
// $wrap: 180px;
$wrap: 360px;
$header-height: 80px;

body {
  .classmodal {
    width: 1010px !important;

    .ant-modal-body {
      padding: 50px 60px;
    }

    .checkmodeldictation-con {
      h1 {
        font-size: 22px;
        font-weight: 900;
        color: #17181A;
        margin-bottom: 30px;
      }

      // margin-bottom: 36px;
      .studentlist {
        .studentlist_top {

          height: 100px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          h1 {
            margin-bottom: 0;
          }

          .studentlist_right {
            display: flex;
            position: relative;

            span {
              width: 93px;
              height: 32px;
              border-radius: 4px;
              font-weight: 400;
              color: #17181A;
              line-height: 32px;
              font-size: 14px;
              text-align: center;
              margin-left: 10px;
              font-family: PingFangSC-Regular, PingFang SC;

              img {
                margin-top: -3px;
                margin-right: 6px;
              }
            }

            .newcourse {
              background: #2252F2;
              color: #FFFFFF;
            }

            .record {
              line-height: 30px;
              border: 1px solid #8E9AB3;

            }

          }
        }

        .creatclass {

          .creatclass_titlename,
          .creatclass_titlestudent {

            .creatclass_titlename_con,
            .creatclass_titlestudent_con {
              position: relative;
              display: inline;

              div {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 6px;
                background: #2252F2;
                border-radius: 3px;
                opacity: 0.2;
              }
            }
            .titlename_input{
              margin: 25px 0 40px;
              border-bottom: 1px solid #2252F2;
            }

          }
          .creatclass_titlestudent{
            margin-bottom: 20px;
          }
        }

        .classStudent {
          padding-bottom: 25px;

          h1 {
            margin-bottom: 30px;
          }
        }
      }



      .con-list {
        padding-top: 10px;
        border-top: 1px solid rgba(214, 216, 220, 0.6);

        .con-list_select {
          display: flex;
          justify-content: space-between;

          .ant-select-selector {
            border: 1px solid #d9d9d9;
            box-shadow: none;
          }
        }

        table {
          border-collapse: separate;
          /*这是该属性的默认值，可以不要*/
          border-spacing: 0 10px;

          thead {
            padding-right: 30px;

            tr {
              height: auto !important;
            }
          }

          tbody {
            width: 100%;
            display: block;
            max-height: 400px;
            padding-right: 20px;
            overflow-y: scroll;
          }

          tbody::-webkit-scrollbar {
            width: 8px;
          }

          tbody::-webkit-scrollbar-thumb {
            background: #D6D8DC;
            border-radius: 15px;
          }

          tbody::-webkit-scrollbar-track-piece {
            background: transparent;
          }
        }

        table thead,
        tbody tr {
          display: table;
          width: 100%;
          table-layout: fixed;
        }

        .altrowstable {
          width: 100%;

          tr {
            width: 890px;
            height: 50px;
            border-radius: 4px;

            th {
              font-size: 14px;
              font-weight: 500;
              color: #8E9AB3;
              // padding-left: 30px;

              .selectxuhaowrap {
                display: flex;
                align-items: center;

                .selectxuhao {
                  width: 14px;
                  height: 14px;
                  background: #FFFFFF;
                  border-radius: 4px;
                  border: 1px solid #DCDFE6;
                  margin-right: 20px;
                }

                .pass {
                  @include bg-img-zhong-zhi('icon_blue_checked.png');
                  background-size: 100% 100%;
                  // background: #2252F2;
                }
              }
            }

            .statewrap {
              display: flex;
              align-items: center;

              .state {
                width: 8px;
                height: 8px;
                border-radius: 4px;
                margin-right: 6px;
                background: #FFA900;
              }

              .pass {
                background: #34B53A;
              }

              .nopass {
                background: #FB0733;
              }
            }

            td {
              border-bottom: 1px solid rgba(214, 216, 220, 0.6);
              border-top: 1px solid rgba(214, 216, 220, 0.6);
              font-size: 14px;
              font-weight: 400;
              color: #252C31;
              height: 50px;

              .selectxuhaowrap {
                display: flex;
                align-items: center;

                .selectxuhao {
                  width: 14px;
                  height: 14px;
                  background: #FFFFFF;
                  border-radius: 4px;
                  border: 1px solid #DCDFE6;
                  margin-right: 20px;
                }

                .pass {
                  // background: #2252F2;
                  @include bg-img-zhong-zhi('icon_blue_checked.png');
                  background-size: 100% 100%;
                }
              }
            }

            td:first-child {
              border-left: 1px solid rgba(214, 216, 220, 0.6);
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
              padding-left: 30px;
            }

            th:first-child {
              padding-left: 30px;
            }

            td:nth-last-child(1) {
              border-right: 1px solid rgba(214, 216, 220, 0.6);
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            }
          }
          .selecttr{

            td{
              background: #F5F6F8;
            }
          }
          .evenrowcolor {
            // background-color: #d4e3e5;

          }

          .oddrowcolor {
            background-color: #F5F6F8;

          }
        }
      }
      .pd-20{
        padding-top: 20px;
      }
      .no-border{
        border-top: none;
      }
      .news_footer {
        padding-top: 10px;

      }

      .input {
        text-align: center;
        padding-top: 40px;

        .input_no {
          width: 69px;
          height: 32px;
          border-radius: 4px;
          background: #fff;
          border: 1px solid #E9ECF0;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8E9AB3;
          line-height: 20px;
          margin: 0 10px;
        }

        .input_save {
          width: 69px;
          height: 32px;
          background: #2252F2;
          border: none;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 20px;
        }
      }
    }
  }

  .ant-popover {

    .ant-popover-arrow {
      .ant-popover-arrow-content {}
    }

    .ant-popover-inner {

      .ant-popover-inner-content {
        padding: 8px 0;
        box-shadow: 0px 0px 6px 0px rgba(23, 24, 26, 0.1);
        border-radius: 4px;

        .popclasscon {
          text-align: center;

          p {
            font-family: PingFangSC-Regular, PingFang SC;
            width: 88px;
            height: 34px;
            line-height: 34px;
            font-weight: 400;
            color: #17181A;
            font-size: 14px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.pages_class_learning {
  background: #F9F9F9;

  .class_learning {
    margin: 0 360px;
    padding-top: 23px;

    .class_learning_title {
      height: 45px;
      display: flex;
      // margin: 0 10px;
      // background-color: #2252F2;
      border-bottom: 1px solid rgba(214, 216, 220, 0.6);
      justify-content: space-between;

      .main_right_top_mycourse {
        display: flex;
        align-items: flex-end;

        span {
          width: 96px;
          height: 36px;
          border-radius: 5px 5px 0px 0px;
          margin-right: 5px;
          font-weight: 400;
          font-size: 14px;
          line-height: 36px;
          text-align: center;
          border: 1px solid rgba(214, 216, 220, 0.6);
          border-bottom: none;
          color: #17181A;
          cursor: pointer;
        }

        .spanselect {
          background: #2252F2;
          border: none;
          color: #FFFFFF;
        }
      }

    }

    // --
    .class_main {
      // background: #FFF;
      display: flex;
      padding: 20px 0 30px;
      box-sizing: border-box;

      .main_left {
        width: 280px;
        background: #FFF;
        margin-right: 20px;
        // padding-top: 10px;
        // text-align: center;
        min-height: 722px;
        border-radius: 4px;
        box-shadow: 0px 0px 6px 0px rgba(23, 24, 26, 0.1);

        .main_left_title {
          font-weight: 500;
          color: #17181A;
          height: 60px;
          display: flex;
          justify-content: space-between;
          padding: 20px;
          font-size: 16px;
          border-bottom: 1px solid rgba(214, 216, 220, 0.6);

          .main_left_titlename {
            position: relative;

            div {
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 6px;
              background: #2252F2;
              border-radius: 3px;
              opacity: 0.2;
            }
          }

          .main_left_titlejieke {
            display: flex;
            align-items: center;
            cursor:pointer;
            img {
              height: 16px;
              width: 16px;
              margin-right: 4px;
            }
          }
        }

        .main_left_con {
          padding: 5px 10px;

          .main_left_con_every {
            width: 260px;
            height: 45px;
            border-radius: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px;
            font-weight: 500;
            color: #17181A;
            line-height: 20px;
            font-size: 14px;

            .img_box{
              width: 30px;
              height: 100%;
              cursor: pointer;
              display: grid;
              place-items: center;
              img {
                width: 15px;
                height: 3px;
              }
            }
          }

          .main_left_con_everyselect {
            background: #E9EFFE;
            color: #2252F2;
          }
        }

        .establish_class {
          margin: 5px 20px;
          width: 240px;
          height: 40px;
          line-height: 38px;
          background: rgba(255, 255, 255, 0);
          border-radius: 8px;
          border: 1px solid #2252F2;
          text-align: center;
          font-weight: 400;
          color: #2252F2;
          font-size: 14px;
          cursor: pointer;
          img {
            margin-right: 4px;
            margin-bottom: 3px;
          }
        }
      }

      .main_right {
        width: 900px;
        background: #FFF;
        position: relative;
        .main_right_title {
          height: 60px;
          font-weight: 500;
          color: #17181A;
          display: flex;
          justify-content: space-between;
          padding: 15px 30px;
          font-size: 16px;
          border-bottom: 1px solid rgba(214, 216, 220, 0.6);

          .main_right_titlename {
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #17181A;
          }

          .main_right_titlestudent {
            display: flex;
            align-items: center;
            font-weight: 400;
            color: #686F7E;
            font-size: 14px;
            cursor: pointer;
          }
        }

        .learning_trend {
          padding: 30px;

          h1 {
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #17181A;
            line-height: 22px;
            margin-bottom: 20px;
          }

          .learning_trend_con {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            min-height: 300px;
            width: 100%;
            position: relative;
            .trend_con {
              // background: chartreuse;
              height: 240px;
              width: 405px;
              margin-bottom: 40px;
            }
          }
        }

        .details_statistical {
          padding: 30px;

          h1 {
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #17181A;
            line-height: 22px;
          }

          .con-list {
            margin-top: 10px;

            .altrowstable {
              width: 100%;
              border-collapse: collapse;

              tr {
                height: 50px;

                th {
                  font-size: 14px;
                  font-weight: 500;
                  color: #8E9AB3;
                  padding-left: 20px;
                }


                td {
                  padding-left: 20px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #17181A;
                }
              }

              .evenrowcolor {
                // background-color: #d4e3e5;

              }

              .oddrowcolor {
                background-color: #F5F6F8;

              }
            }
          }
        }
      }
    }

    .finish_class {
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      padding:30px;
      margin: 20px 0;
      min-height: 722px;
      background: #FFF;
      box-shadow: 0px 0px 6px 0px rgba(23, 24, 26, 0.1);
      border-radius: 4px;
      .finish_class_every{
        height: 190px;
        width: 33.33%;
        .every {
          height: 190px;
          width: 340px;
          background: #F4F8FF;
         
          border-radius: 8px;
          margin-bottom: 40px;
          background-repeat: no-repeat;
          @include bg-img-zhong-zhi('cardbac.png');
          background-size: 100%;
          padding: 30px;
          margin: auto;
          h1 {
            font-size: 22px;
            font-weight: 500;
            color: #17181A;
            line-height: 22px;
            margin-bottom: 50px;
          }
  
          .finish_class_every_bottom {
            display: flex;
            justify-content: space-between;
  
            .finish_left {
              font-size: 14px;
              font-weight: 400;
              color: #686F7E;
  
              p {
                margin-bottom: 10px;
                line-height: 20px;
              }
            }
  
            .finish_right {
              font-size: 14px;
              padding-top: 20px;
              font-weight: 400;
              color: #686F7E;
              line-height: 14px;
              text-align: end;
  
              span {
                font-size: 34px;
                font-weight: 600;
                color: #2252F2;
                line-height: 34px;
              }
            }
  
          }
        }
        .every:hover{
          box-shadow: 0px 10px 18px 0px rgba(23, 24, 26, 0.14);
          transition: all 0.3s;
        }
      }
      
    }
  }
}
