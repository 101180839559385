@mixin margin($val: 1, $pos: all) {
  @if $pos == all {
    margin: #{$val}px !important;
  } @else if $pos == x {
    margin-left: #{$val}px !important;
    margin-right: #{$val}px !important;
  } @else if $pos == y {
    margin-top: #{$val}px !important;
    margin-bottom: #{$val}px !important;
  } @else {
    margin-#{$pos}: #{$val}px !important;
  }
}

@mixin padding($val: 1, $pos: all) {
  @if $pos == all {
    padding: #{$val}px !important;
  } @else if $pos == x {
    padding-left: #{$val}px !important;
    padding-right: #{$val}px !important;
  } @else if $pos == y {
    padding-top: #{$val}px !important;
    padding-bottom: #{$val}px !important;
  } @else {
    padding-#{$pos}: #{$val}px !important;
  }
}

@mixin border($pos: all, $c: $c-d8, $val: 1px) {
  @if $pos == all {
    border: #{$val} solid #{$c};
  } @else if $pos == x {
    border-left: #{$val} solid #{$c};
    border-right: #{$val} solid #{$c};
  } @else if $pos == y {
    border-top: #{$val} solid #{$c};
    border-left: #{$val} solid #{$c};
  } @else {
    border-#{$pos}: #{$val} solid #{$c};
  }
}

@mixin bg-img-zhong-zhi($url: '') {
  background-image: url('/resources/images/#{$url}');
}

@mixin border($w, $c, $pos: all) {
  @if $pos == all {
    border: #{$w}px solid $c;
  } @else if $pos == x {
    border-left: #{$w}px solid $c;
    border-right: #{$w}px solid $c;
  } @else if $pos == y {
    border-top: #{$w}px solid $c;
    border-bottom: #{$w}px solid $c;
  } @else {
    border-#{$pos}: #{$w}px solid $c;
  }
}

@mixin triangle($direction: bottom, $w: 30, $h: 20, $c: $c-main) {
  @if $direction == bottom {
    @include border($w / 2, transparent, x);
    @include border($h, $c, top);
  }
  @if $direction == top {
    @include border($w / 2, transparent, x);
    @include border($h, $c, bottom);
  }
  @if $direction == left {
    @include border($h / 2, transparent, y);
    @include border($w, $c, right);
  }
  @if $direction == right {
    @include border($h / 2, transparent, y);
    @include border($w, $c, left);
  }
}

$white: #fff;
$c-main: #13BB74;
$black: #000;
$c333: #333;
$c666: #666;
$c999: #999;
$c-d8: #D8D8D8;
$c-eb: #EBEBEB;
$c-text: #505253;
$m-hook: #3473f0;
// $wrap: 180px;
$wrap: 360px;
$header-height: 80px;

body {
  // max-width: 1920px;
  margin: 0 auto;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
div,
dl,
dt,
ol,
dd {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

[ant-click-animating-without-extra-node='false']::after,
.auto-bg {
  overflow: hidden;
  position: relative;
  background-size: cover;
  background: no-repeat center top;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &-2 {
    overflow: hidden;
    line-height: 1.5;
    height: 3em;
  }

  &-3 {
    overflow: hidden;
    line-height: 1.5;
    height: 4.5em;
  }

  &-4 {
    overflow: hidden;
    line-height: 1.5;
    height: 6em;
  }

  &-5 {
    overflow: hidden;
    line-height: 1.5;
    height: 7.5em;
  }
}

.c {
  &-main {
    color: $c-main;
  }

  &-333 {
    color: $c333;
  }

  &-666 {
    color: $c666;
  }

  &-999 {
    color: $c999;
  }

  &-text {
    color: $c-text;
  }

  &-white {
    color: $white;
  }

  &-black {
    color: $black;
  }
}

.wrap {
  margin: $wrap;
}

@for $index from 1 through 60 {
  .f-#{$index} {
    font-size: #{$index}px;
  }

  .mag {
    &-#{$index} {
      @include margin($index);
    }

    &-x-#{$index} {
      @include margin($index, x);
    }

    &-y-#{$index} {
      @include margin($index, y);
    }

    &-t-#{$index} {
      @include margin($index, top);
    }

    &-b-#{$index} {
      @include margin($index, bottom);
    }

    &-l-#{$index} {
      @include margin($index, left);
    }

    &-r-#{$index} {
      @include margin($index, right);
    }
  }

  .pad {
    &-#{$index} {
      @include padding($index);
    }

    &-x-#{$index} {
      @include padding($index, x);
    }

    &-y-#{$index} {
      @include padding($index, y);
    }

    &-t-#{$index} {
      @include padding($index, top);
    }

    &-b-#{$index} {
      @include padding($index, bottom);
    }

    &-l-#{$index} {
      @include padding($index, left);
    }

    &-r-#{$index} {
      @include padding($index, right);
    }
  }
}

.echarts-for-react>div img {
  position: absolute;
  top: 34%;
  right: 34%;
  width: 16%;
  height: 16%;
}

#echarsContainer .echarts-for-react>div img {
  position: absolute;
  top: 34%;
  right: 34%;
  width: 16%;
  height: 16%;
}

#root {
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
}

span.black_space_0001 {
  display: inline-block;
  text-decoration: underline;
}

table.table1 {
  margin-top: 20px;
  margin-bottom: 20px;
  border-spacing: 0;
  border-collapse: collapse;

  tr td {
    padding-left: 8px;
    border: 1px solid #e6e6e6;
    vertical-align: middle;
  }
}

.title {
  text-align: center;
  hyphenate: auto;
}

.underpoint {
  background: rgba(0, 0, 0, 0) url("http://whdx-admin.bj.bcebos.com/point.png?authorization=bce-auth-v1%2Fc61a6808813c4c2ab9b265c9a429588f%2F2017-06-14T03%3A34%3A56Z%2F-1%2Fhost%2F80c538b7810a1df191c0c2ecf42e3536060ecac1e8d6e2baf2b313aa5d4c5279") no-repeat scroll center bottom;
  padding-bottom: 3px;
  background-size: 5px;
  // font-size: 18px;
}

.underlinewavy {
  background: url("http://whdx-admin.cdn.bcebos.com/v1/%E8%AF%AD%E6%96%87/202009/1600227243448_wave.png?authorization=bce-auth-v1%2Fc61a6808813c4c2ab9b265c9a429588f%2F2020-09-16T03%3A34%3A03Z%2F-1%2F%2Ffe9608030d96a0fd0c0c702f1968af1d8e5b33e63c75b00fa875c99c8842149f") bottom repeat-x;
  padding-bottom: 1px;
}

.p1 {
  text-align: justify;
  hyphenate: auto;
}

.p3 {
  text-align: center;
  font-family: Times New Roman;
  font-size: 10pt;
}

// pad pc 上标和下标统一设置位置
sub,
.sub {
  // vertical-align: sub;
  position: relative;
  bottom: -.4em !important;
}

// pad pc 上标和下标统一设置位置
sup,
.sup {
  // vertical-align: super;
  position: relative;
  top: -.6em !important;
  vertical-align: baseline !important;
}

// pad pc 上标和下标统一设置字体大小
.sup,
sup,
sub,
.sub {
  font-size: .65em !important;
  // background: pink;
}

.td1 {
  width: 0.82222223in;
  padding-start: 0.075in;
  padding-end: 0.075in;
  border-bottom: thin solid black;
  border-left: thin solid black;
  border-right: thin solid black;
  border-top: thin solid black;
}

.td2 {
  width: 5.327778in;
  padding-start: 0.075in;
  padding-end: 0.075in;
  border-bottom: thin solid black;
  border-left: thin solid black;
  border-right: thin solid black;
  border-top: thin solid black;
}

.underline {
  text-decoration: underline;
}

// .table1 {
//   margin-left: 5%;
//   margin-right: 5%;
//   width: 90%;
//   padding-start: 0.075in;
//   padding-end: 0.075in;
//   border-bottom: thin solid black;
//   border-left: thin solid black;
//   border-right: thin solid black;
//   border-top: thin solid black
// }

.s1 {
  font-weight: bold;
}

.t1 {
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
}

// .table1 td {
//   text-align: center;
//   width: 0.82222223in;
//   padding-start: 0.075in;
//   padding-end: 0.075in;
//   border-bottom: thin solid black;
//   border-left: thin solid black;
//   border-right: thin solid black;
//   border-top: thin solid black;
// }

td img {
  max-width: none !important;
}

.wordDivDot {
  position: relative;

  &::after {
    display: block;
    position: absolute;
    content: ' ';
    width: 4px;
    height: 4px;
    text-align: center;
    background-color: black;
    border-radius: 50%;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.wordTestTable {
  width: 100%;
}

.mjx-char {
  // padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mjx-charbox {
  width: auto !important;
}

u,
i {
  font-size: inherit;
}

// 分数字体大小
.mjx-numerator {
  font-size: inherit !important;
}

em {
  font-style: italic !important;
  font-size: inherit !important;
}

b,
strong {
  font-size: inherit !important;
}

.rightTransForm {
  font-family: 'Noto', 'Sans', 'CJK', 'SC';
}

.mjx-box.MJXc-stacked {
  padding: 0px 0.82em !important;
}

.mjx-line {
  min-width: 0.85em;
}

// mjx-container[jax="SVG"] > svg {
//   font-size: 16px !important;
//   font-weight: lighter !important;
//   // vertical-align: middle !important;
// }

.set-font-family div,
.set-font-family p {
  font-family: 'Times New Roman', Times, serif, KaiTi, SimSun !important;
}

.MathJax {
  font-family: Arial, Chandara;

  text {
    transform: scale(1, -1) !important;
    font-size: 977.8px !important;
    font-family: serif !important;
  }
}

body>iframe {
  display: none !important;
  position: relative !important;
}

.ant-input:hover,
.ant-input-focused,
.ant-input:focus,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #2252F2 !important;
  box-shadow: 0 0 0 2px transparent !important;
}

.ant-input-affix-wrapper {
  margin-bottom: 15px;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #2252F2 !important;
}

.ant-message {
  top: 55px;
}

.ant-message span {
  vertical-align: middle;
}
